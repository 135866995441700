import "styles/pages/page-contact.scss";

import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import axios from "axios";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";

import UnsubscribePattern from "assets/images/unsubscribe-pattern.png";

const UnsubscribeWrapper = styled.section`
  padding: 100px 0;
  background-image: url(${UnsubscribePattern});
  background-repeat: no-repeat;
  background-position: center;

  h3 {
    font-size: 36px;
    text-align: center;
  }

  p {
    font-size: 24px;
    text-align: center;
    margin: 20px 0 40px;

    a {
      text-decoration: underline;
    }
  }
`;

const UnsubscribeForm = styled.form`
  max-width: 580px;
  margin: 0 auto;

  input {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    font-size: 12px;
    color: #17204f;
    padding: 12px 15px;
    min-height: 50px;
    border: none;
    outline: none;
    appearance: none;
    margin-bottom: 30px;
    border: 1px solid #dddddd;

    &::placeholder {
      font-size: 12px;
      color: #17204f;
    }
  }
`;

const UnsubscribeCta = styled.div`
  text-align: center;
  margin: 75px 0 0 0;
`;

const Unsubscribe = () => {
  const [email, setEmail] = useState("");
  const [send, setSend] = useState(false);
  const [success, setSuccess] = useState(false);

  const resetForm = () => {
    setEmail("");
    setSend(false);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setSend(true);
    setSuccess(true);

    const formData = new FormData();
    formData.set("email", email);

    axios({
      method: "post",
      url: "https://wp.prodinity.com/wp-json/contact-form-7/v1/contact-forms/1757/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        console.log("Submit success");
        resetForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Layout>
      <Seo title="Unsubscribe" />
      <SubpageHeader
        title="Unsubscribe"
        image={require("../assets/images/unsubscribe-bg.jpg").default}
      />

      <UnsubscribeWrapper>
        <div className="container">
          <h3>We are sorry to see you go!</h3>
          <p>
            If you change your mind in the future, please feel free to{" "}
            <Link to="/contact/">contact</Link> us.
          </p>
          {success ? (
            <p>Sorry to say goodbye...</p>
          ) : (
            <UnsubscribeForm onSubmit={formSubmit}>
              <div className="row">
                <div className="col-sm-8">
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-4">
                  <button type="submit" className="btn btn--small btn--block">
                    {send === true ? "Processing..." : "Unsubscribe"}
                  </button>
                </div>
              </div>
            </UnsubscribeForm>
          )}
        </div>

        <UnsubscribeCta>
          <Link to="/" className="btn">
            Main page
          </Link>
        </UnsubscribeCta>
      </UnsubscribeWrapper>
    </Layout>
  );
};

export default Unsubscribe;
